body {
    margin: 0;
    padding: 0;
    cursor: default;
    overflow-x: hidden;
}

.parent {
    background-color: #e4ebf5;
    color: #222222;
}

.parent-dark {
    background-color: #444444;
    color: floralwhite;
}

.dark-side {
    color: #EB212E;
    text-shadow: 0 0 7px #EB212E;
}

.light-side {
    color: #15D2FD;
    text-shadow: 0 0 7px #15D2FD;
}

.description-text-dark-button {
    font-size: 12px;
    text-align: start;
    line-height: 1.2;
}

.app {
    display: flex;
    font-size: calc(10px + 2vmin);
    text-align: center;
    width: 80vw;
    min-height: 100vh;
    margin: 0 auto;
    transition: all 0.4s ease-in-out;
    font-family: 'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.title-text {
    font-size: 24px;
    font-weight: bold;
}

.title-text-path {
    font-size: 24px;
}

.toggle-buttons {
    margin: 8px 8px 8px 8px;
}

.toggle-buttons-text {
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 8px 8px 8px;
}

.communication-buttons {
    width: 24px;
    height: 24px;
    margin: 8px 8px 8px 8px;
}

.communication-buttons-image {
    width: 24px;
    height: 24px;
}

.full-width {
    width: 100%;
}

.three-quarter-width {
    width: 75%;
}

.flex {
    display: flex;
    display: -webkit-flex;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.flex-wrap {
    flex-wrap: wrap;
}

.space-between {
    justify-content: space-between;
}

.padding-sm {
    padding: 6px;
}

.padding-md {
    padding: 16px;
}

.horizontal-padding-md {
    padding: 0 16px 0 16px;
}

.padding-lg {
    padding: 32px;
}

.horizontal-padding-lg {
    padding: 0 32px 0 32px;
}

.align-items-center {
    align-items: center;
}

.align-items-start {
    align-items: start;
}

.avatar {
    margin: 0 3px 0 3px;
    cursor: pointer;
    width: 48px;
    height: 48px;
}

.dark-mode-image {
    width: 18px;
    height: 18px;
    margin: 2px;
}

.intro-text-title {
    font-size: 48px;
    font-weight: bold;
    opacity: 0.8;
}

.intro-text-subtitle {
    font-size: 24px;
    font-weight: bold;
    opacity: 0.7;
    text-align: start;
}

.description-text-para {
    font-size: 20px;
    font-weight: bold;
    opacity: 0.6;
    text-align: start;
    line-height: 1.2;
}

.testimonial-avatar {
    margin: 3px;
    width: 48px;
    height: 48px;
}

.testimonial-name-text {
    font-size: 20px;
    font-weight: bold;
    opacity: 0.9;
    text-align: start;
    line-height: 1.2;
}

.testimonial-subtitle-text {
    margin: 2px 0px 0px 0px;
    font-size: 18px;
    opacity: 0.8;
    text-align: start;
    line-height: 1.2;
}

.testimonial-review-text {
    margin: 4px 0px 0px 0px;
    font-size: 16px;
    opacity: 0.6;
    text-align: start;
    line-height: 1.2;
    white-space: pre-wrap;
}

.footer-text {
    font-size: 16px;
    opacity: 0.8;
}

@media (max-width: 760px) {

    .app {
        text-align: center;
        width: 95vw;
        min-height: 100vh;
        margin: 0 auto;
    }

    .title-text {
        font-size: 20px;
        font-weight: bold;
    }

    .title-text-path {
        font-size: 20px;
    }

    .avatar {
        width: 36px;
        height: 36px;
    }

    .intro-text-title {
        font-size: 32px;
        font-weight: bold;
        opacity: 0.8;
    }

    .intro-text-subtitle {
        font-size: 16px;
        font-weight: bold;
        opacity: 0.7
    }

    .description-text-para {
        font-size: 14px;
        font-weight: bold;
        opacity: 0.6;
        text-align: start;
        line-height: 1.2;
    }

    .toggle-buttons-text {
        text-transform: capitalize;
        font-size: 12px;
        padding: 8px 8px 8px 8px;
    }

    .footer-text {
        font-size: 10px;
        opacity: 0.8;
    }

}

@media (max-width: 480px) {

    .app {
        text-align: center;
        width: 95vw;
        min-height: 100vh;
        margin: 0 auto;
    }

    .title-text {
        font-size: 16px;
        font-weight: bold;
    }

    .title-text-path {
        font-size: 16px;
    }

    .avatar {
        width: 36px;
        height: 36px;
    }

    .intro-text-title {
        font-size: 24px;
        font-weight: bold;
        opacity: 0.8;
    }

    .intro-text-subtitle {
        font-size: 18px;
        font-weight: bold;
        opacity: 0.7
    }

    .description-text-para {
        font-size: 14px;
        font-weight: bold;
        opacity: 0.6;
        text-align: start;
        line-height: 1.2;
    }

    .toggle-buttons-text {
        text-transform: capitalize;
        font-size: 12px;
        padding: 8px 8px 8px 8px;
    }

    .testimonial-avatar {
        margin: 3px;
        width: 36px;
        height: 36px;
    }

    .testimonial-name-text {
        font-size: 18px;
        font-weight: bold;
        opacity: 0.9;
        text-align: start;
        line-height: 1.2;
    }

    .testimonial-subtitle-text {
        margin: 2px 0px 0px 0px;
        font-size: 16px;
        opacity: 0.8;
        text-align: start;
        line-height: 1.2;
    }

    .testimonial-review-text {
        margin: 4px 0px 0px 0px;
        font-size: 14px;
        opacity: 0.6;
        text-align: start;
        line-height: 1.2;
    }

    .footer-text {
        font-size: 10px;
        opacity: 0.8;
    }
}